<template>
  <a-layout>
    <Header />
    <div class="container">
      <ul class="func-items">
        <li>
          <a href="https://gemini.bubianli.com/">🤖 Gemini Chat </a>
        </li>
        <li>
          <a href="https://prompt.bubianli.com/">🐻 提示词英雄（Prompt Hero）  🔥</a>
        </li>
        <li>
          <a href="https://bubianli.com/api/tools/ip" target="_blank" >🔭 你的 IP</a>
        </li>
        <li>
          <a href="https://bubianli.com/page/5g_bandwidth/index.html" target="_blank">📱 5G Bandwidth Calculator</a>
        </li>
        <li>
          <a href="/bilibili">📺 bilibili 视频下载中心 </a>
        </li>
         <li>
          <a href="/array_duplicate">📖 文字去重工具 </a>
        </li>
         <li>
          <a href="/three_big">😸 知乎「三巨头」处理 </a>
        </li>
        <li>
          <a href="/home/quote">👌🏻 字符串转义 </a>
        </li>
        <li>
          <a href="/calendar">📆 排期日历 🔥</a>
        </li>
        <li>
          <a href="/mask_say" >🤡 埃隆马斯克谣言生成器 </a>
        </li>
        <li>
          <a href="https://www.todoso.cn/" target="_blank">🗓 TODOSO 待办管理工具</a>
        </li>
         <li>
          <a href="https://sblt.bubianli.com"  target="_blank" > 🍩 状态便利贴 - 后疫情时代的元宇宙 ID 产品（内测中）</a>
        </li>
        <li>
          <a href="http://unreal-texas.bubianli.com" target="_blank" >♠ 不可思议，德克萨系 - 德州扑克 AI 模拟回测系统</a>
        </li>
        <li>
          <a href="/futu_fee"  target="_blank" > 💰 富途牛牛手续费速算</a>
        </li>
        <li>
          <a href="https://chenjinya.com/workday/" target="_blank" >
            🛵 工作日计算器
          </a>
        </li>
        <li>
          <a href="http://chenjinya.com/go-for-javascript-developers/index.html"
          target="_blank" >
           📔《Go for Javascript Developers》中文版翻译
          </a>
        </li>
      </ul>
    </div>
    <div style="text-align: center; margin: 20px auto;">
      <img style="width: 30%;" src="../assets/eva-bubianli.jpg" />
      <img style="width: 30%;"   src="../assets/eva-bubianli-2.jpg" />
    </div>
    <Footer />
  </a-layout>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";

export default {
  name: "Dashboard",
  props: {
    query: String,
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      searching: false,
      token: "",
      video_info: {
        title: "",
        video_url: "",
        cover_url: "",
      },
    };
  },
  mounted: function () {},
  watch: {},
  computed: {},
  methods: {
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}

.func-items {
}

.func-items li {
  list-style: none;
  
}

.func-items li a {
  display: inline-block;
  padding: 8px;
}

.func-items li a:hover {
  background: #999;
  color: #fff;
}

.func-items li img {
  width: 100px;
}

</style>
