import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Antd from 'ant-design-vue';
// import VueAxios from 'vue-axios' 

import 'ant-design-vue/dist/antd.less';
import store from './store'
import routes from './routes'
import { request } from '@/utils'
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Antd)
// Vue.use(VueAxios, axios)
Vue.prototype.$http = request
Vue.config.productionTip = false

//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  render: h => h(App),
  store: new Vuex.Store(store),
  router: new VueRouter({ 
    mode: 'history',
    routes
  })
}).$mount('#app')
