
const UserInfo = {
  email: "",
      name: "",
      nonce: "",
      phone: "",
      realm: "",
      sessionId: "",
      shopId: "",
      shopName: "",
}
export default  {
  state: {
    modules:{
      loading: {
        visible: false,
        text: '加载中...'
      }
    },
    userInfo:{
      ...UserInfo
    },
    count: 0
  },
  mutations: {
    loading(state, cnf) {
      let visible = true
      let text = '加载中...'
      if (typeof cnf == 'string') {
        visible = true
        text = cnf
      } else if (typeof cnf == 'boolean') {
        visible = cnf
      } else if (typeof cnf == 'object') {
        visible = cnf.visible
        text = cnf.text
      }
     
      state.modules.loading = {
        visible,
        text
      }
      return
    },
    login(state, userInfo) {
      if(null == userInfo) {
        state.userInfo = {
          ...UserInfo
        }
        return
      }
      state.userInfo = {
        ...state.userInfo,
        ...userInfo
      }
    },
    increment (state) {
      state.count++
    }
  }
}